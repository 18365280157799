/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { required } from '../utils/validators'
import { useLoad } from '../hooks/request'
import { EMPLOYEE_LIST, SALES_CHANNEL_LIST, SUBJECT_LIST } from '../urls'
import Button from './common/Button'
import Input from './common/Input'
import Select from './common/Select'
import MultiSelect from './common/MultiSelect'
import { Radio, RadioGroup } from './common/Radio'
import { integersOnly } from '../utils/number'
import { map } from 'lodash'
import { lessonType, phoneOwnerOptions } from "../utils/position";


export default function CustomerForm({ loading, onSubmit, onCancel, values }) {
    const [advanced, setAdvanced] = useState(false)
    const subject = useLoad({ url: SUBJECT_LIST })
    const subjectOptions = subject.response?.results || []
    const salesChannel = useLoad({ url: SALES_CHANNEL_LIST })
    const salesChannelOptions = salesChannel.response?.results || []

    const teachers = useLoad({
        url: EMPLOYEE_LIST, params: { positions: 'teacher', size: 1000, isArchive: 'archive' }
    })
    const teachersOptions = teachers.response?.results || []

    // const branch = useLoad({ url: BRANCH_LIST })

    const handleOnSubmit = (vals, actions) => {
        const data = {
            ...vals,
            birthday: vals.birthday || undefined,
            phone: integersOnly(vals.phone),
            phoneSecond: integersOnly(vals.phoneSecond),
            phoneThird: integersOnly(vals.phoneThird),
            subject: map(vals.subject, 'id'),
            teacher: map(vals.teacher, 'id'),
        }
        onSubmit(data, actions)
    }

    return (
        <Formik enableReinitialize validateOnBlur={false} validateOnChange={false} onSubmit={handleOnSubmit} initialValues={{
            name: '',
            phone: '',
            phoneOwner: '',
            phoneSecondOwner: '',
            phoneThirdOwner: '',
            subject:[],
            teacher:[],
            ...values,
            lessonTypes: values?.lessonTypes || '',
            phoneSecond: values?.phoneSecond || '',
            phoneThird: values?.phoneThird || '',
            student_image:null,
            birthday: values?.birthday || '',
            advert: values?.advert?.id || '',
            groupType: values?.groupType?.id || '',
            description: values?.description || ''
        }}>
            {({}) => {
                return (
                    <Form>
                        <Input
                            name="name"
                            label="Имя"
                            validate={required}
                            autoFocus
                            autoComplete="off"
                            disabled={loading}
                        />

                        <Input
                            name="birthday"
                            type="date"
                            label="День рождения"
                            // validate={required}
                            disabled={loading}
                        />

                        <Input
                            name="phone"
                            type="phone"
                            validate={required}
                            label="Номер телефона"
                            disabled={loading}
                        />
                        <RadioGroup name={'phoneOwner'} style={{display:'flex', gap:'1rem'}}>
                            {phoneOwnerOptions.map(item => (
                                <Radio key={item.value} value={item.value} label={item.label} />
                            ))}
                        </RadioGroup>

                        <hr />

                        <Input
                            optional
                            name="phoneSecond"
                            type="phone"
                            label="Номер телефона (дополнительно)"
                            disabled={loading}
                        />
                        <RadioGroup name={'phoneSecondOwner'} style={{display:'flex', gap:'1rem'}}>
                            {phoneOwnerOptions.map(item => (
                                <Radio key={item.value} value={item.value} label={item.label} />
                            ))}
                        </RadioGroup>

                        <hr />

                        <Input
                            optional
                            name="phoneThird"
                            type="phone"
                            label="Номер телефона (дополнительно)"
                            disabled={loading}
                        />
                        <RadioGroup name={'phoneThirdOwner'} style={{display:'flex', gap:'1rem'}}>
                            {phoneOwnerOptions.map(item => (
                                <Radio key={item.value} value={item.value} label={item.label} />
                            ))}
                        </RadioGroup>

                        <Select
                            name="advert"
                            options={salesChannelOptions}
                            label="Канал продаж"
                            help="Добавить канал продаж можно в настройках"
                            loading={salesChannel.loading}
                            validate={required}
                            disabled={loading}
                        />

                        {/* <Select */}
                        {/*    empty */}
                        {/*    name="company" */}
                        {/*    label="Филиал" */}
                        {/*    loading={branch.loading} */}
                        {/*    options={branch.response ? branch.response : []} */}
                        {/*    help="Выберите филиал" */}
                        {/*    validate={required} /> */}

                        <Select
                            empty
                            label={'Тип группы'}
                            name="lessonTypes"
                            options={lessonType}
                            optionValue='value'
                            validate={required}
                            disabled={loading}
                        />

                        <MultiSelect
                            name="subject"
                            label="Предмет"
                            placeholder=''
                            options={subjectOptions}
                            optionLabel='name'
                            optionValue='id'
                            validate={required}
                            disabled={loading}
                            help="Можете добавить предмет в настройках"
                        />

                        <MultiSelect
                            name="teacher"
                            label="Учитель"
                            placeholder=''
                            options={teachersOptions}
                            optionLabel='name'
                            optionValue='id'
                            disabled={loading}
                            optional
                        />

                        <Input
                            name="description"
                            component="textarea"
                            label="Примечание"
                            optional
                            disabled={loading}
                        />

                        {/* <a onClick={() => setAdvanced(!advanced)}>
                            Расширенная форма
                        </a><br />

                        {advanced ? (
                            <div className={css(styles.advanced)}>
                                {cp('academy.add_customer_seller') ? (
                                    <Select
                                        name="seller"
                                        options={employee.response ? employee.response.results : []}
                                        label="Менеджер продаж"
                                        help="Вы можете добавить нового менеджер продаж в меню персонал"
                                        empty={values ? values.seller : false}
                                        loading={employee.loading} />
                                ) : null}
                            </div>
                        ) : null}<br /> */}

                        <div style={{display:'flex', gap:'1rem', justifyContent:'flex-end'}}>
                            <Button
                                loading={loading}
                                text="Сохранить"
                                type="submit"
                                icon="ion-md-checkmark"
                                className="is-success"/>

                            <Button
                                onClick={onCancel}
                                icon="ion-md-close"
                                text="Отмена"
                                className="is-danger" />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = StyleSheet.create({
    advanced: {
        background: 'rgba(251, 248, 248, 1)',
        borderRadius: '.3rem',
        padding: '1rem',
    },
})
