import React, { useRef } from 'react'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { DragDropContext } from 'react-beautiful-dnd'
import { usePutRequest } from '../hooks/request'
import { CUSTOMER_MOVE } from '../urls'
import CustomerBoardColumn from './CustomerBoardColumn'
import { useModal } from '../hooks/modal'
import StudentCreate from './StudentCreate'


function changeCustomerPositionAndColumn(
    customers,
    customerId,
    source,
    destination,
) {
    const sourcePosition = source.index + 1
    const destinationPosition = destination.index + 1

    return customers.map((customer) => {
        const isStateChanged = String(source.droppableId) !== String(destination.droppableId)
        const isMovedUp = sourcePosition < destinationPosition && !isStateChanged
        const isMovedDown = sourcePosition > destinationPosition && !isStateChanged && !isMovedUp
        const positionGtOldPosition = customer.position > sourcePosition
        const positionLtOldPosition = customer.position < sourcePosition
        const srcStateChanged = String(customer.state) === source.droppableId
        const destStateChanged = String(customer.state) === destination.droppableId
        const positionGteNewPosition = customer.position >= destinationPosition
        const positionLteDestPosition = customer.position <= destinationPosition
        const positionGtNewPositionAndStateEState = positionGteNewPosition && destStateChanged

        if (customerId === String(customer.id)) {
            return { ...customer, state: Number(destination.droppableId), position: destinationPosition }
        }

        if ((isStateChanged && positionGtOldPosition && srcStateChanged)
        || (isMovedUp && positionGtOldPosition && positionLteDestPosition && destStateChanged)) {
            return { ...customer, position: customer.position - 1 }
        }

        if ((isStateChanged && positionGtNewPositionAndStateEState)
        || (isMovedDown && positionLtOldPosition && positionGtNewPositionAndStateEState)) {
            return { ...customer, position: customer.position + 1 }
        }

        return customer
    })
}

export default function CustomerBoard({ columns, type, searchParams }) {
    const moveCustomer = usePutRequest()
    const customerInstancesRef = useRef({}); // Use ref to store customer instances
    const acceptedStatus = find(columns, { type: 'accepted' }) || {}
    
    // const [showCustomerAcceptModal, hideCustomerAcceptModal] = useModal((props) => (
        //     <StudentCreate
        //         customer={props.customer}
        //         onCancel={() => hideCustomerAcceptModal()}
        //         onSuccess={async () => {
            //             await onDragEnd({
                //                 source: props.source,
                //                 destination: props.destination,
                //                 draggableId: String(props.customer.id),
                //                 isStudentCreated: true,
                //             })
                //             hideCustomerAcceptModal()
    //         }} />
    // ))

    // async function onDragEnd({ source, destination, draggableId: customerId, isStudentCreated = false }) {
    //     if (!destination) return

    //     const customer = find(customers.response.results, { id: Number(customerId) })
    //     if (destination.droppableId === String(acceptedStatus.id) && !isStudentCreated
    //         && String(source.droppableId) !== String(destination.droppableId) && isEmpty(customer.students)) {
        //         showCustomerAcceptModal({ customer, source, destination })
        //     }
        
        //     // update status
        //     const results = changeCustomerPositionAndColumn(customers.response.results, customerId, source, destination)
        //     customers.setResponse({ results })
        //     const data = { position: destination.index + 1, state: Number(destination.droppableId) }
        //     await moveCustomer.request({ url: CUSTOMER_MOVE.replace('{id}', customerId), data })
        //     onReloadStatues()
        // }
        
    const handleCustomerInstance = (columnId, customerInstance) => {
        customerInstancesRef.current[columnId] = customerInstance;
    };

    const handleDragEnd = async ({ draggableId, source, destination }) => {
        if (!destination) return;
        const data = { position: destination.index + 1, state: Number(destination.droppableId) };
        const {success} = await moveCustomer.request({url:CUSTOMER_MOVE.replace('{id}', draggableId), data})
        // Call the reload function for the source and destination columns
        if(success){
            if (customerInstancesRef.current[source.droppableId]) {
                customerInstancesRef.current[source.droppableId].reload();
            }
            if (customerInstancesRef.current[destination.droppableId]) {
                customerInstancesRef.current[destination.droppableId].reload();
            }
        }
    };
    return (
        <div style={{ overflow: 'auto', height: 'calc(100vh - 12rem)' }}>
            <DragDropContext 
                onDragEnd={handleDragEnd}
            >
                <div style={{ display: 'grid', gap: '1rem', gridAutoFlow: 'column', gridAutoColumns: 'minmax(14rem, 1fr)' }}>
                    {columns.map((column, index) => (
                        <div key={column.id}>
                            <CustomerBoardColumn
                                index={index}
                                columnKey={column.id}
                                showLabels={type === ''}
                                type={type}
                                column={column}
                                lineColor={column.color} 
                                searchParams={searchParams}
                                onCustomerInstance={handleCustomerInstance} // Pass the handleCustomerInstance callback
                            />
                        </div>
                    ))}
                </div>
            </DragDropContext>
        </div>
    )
}
