import React from 'react'
import Layout from '../components/Layout'
import SettingsTabs from '../components/SettingsTabs'
import { useLoad } from '../hooks/request'
import { WALLET_LIST, TRANSACTION_CATEGORY_LIST } from '../urls'
import WalletCreate from '../components/WalletCreate'
import { useModal } from '../hooks/modal'
import Button from '../components/common/Button'
import { checkPermission as cp } from '../utils/auth'
import Table from '../components/common/Table'
import WalletSettingsItem from '../components/WalletSettingsItem'
import Card from '../components/common/Card'
import { useQueryParams } from '../hooks/queryString'
import TransactionCategoryCreate from '../components/TransactionCategoryCreate'
import TransactionCategoryItem from '../components/TransactionCategoryItem'
import { StyleSheet, css } from 'aphrodite'


export default function SettingsFinance() {
    const wallets = useLoad({ url: WALLET_LIST })
    const params = useQueryParams()

    const [showCreteWallet, hideCreateWallet] = useModal(
        <WalletCreate wallets={wallets} onSuccess={() => {
            wallets.request()
            hideCreateWallet()
        }} onCancel={() => hideCreateWallet()} />,
    )

    const transactionCategories = useLoad({
        url: TRANSACTION_CATEGORY_LIST,
        params,
    }, [params.page])

    const [showCreateTransactionCategory, hideCreateTransactionCategory] = useModal(
        <TransactionCategoryCreate onCancel={() => hideCreateTransactionCategory()} onSuccess={async () => {
            await transactionCategories.request()
            hideCreateTransactionCategory()
        }} />,
    )

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <main className={css(styles.cards)}>
                {cp('finance.view_wallet') ? (
                        <Card>
                            <div style={{display:'flex', justifyContent:'space-between', gap:'1rem', alignItems:'flex-end'}} className="is-size-4">
                                Счета

                                {cp('finance.add_wallet') ? (
                                    <Button
                                    onClick={showCreteWallet}
                                    text="Создать счёт"
                                    icon="ion-md-add"
                                    className="is-centered is-link is-outlined" />
                                    ) : null}
                            </div>

                            <Table
                                style={{paddingBottom:50}}
                                totalCount={wallets.response ? wallets.response.count : 0}
                                columns={{ id: '№', name: 'Имя', icon: 'Иконка', actions: '' }}
                                loading={wallets.loading}
                                items={wallets.response ? wallets.response.results : []}
                                renderItem={(item) => (
                                    <WalletSettingsItem key={item.id} item={item} wallets={wallets} />
                                )} />
                        </Card>
                ) : null}

                {cp('finance.view_transactioncategory') ? (
                        <Card>
                            <div style={{display:'flex', justifyContent:'space-between', gap:'1rem', alignItems:'flex-end'}} className="is-size-4">
                                Категория транзакций

                                {cp('finance.add_transactioncategory') ? (
                                    <Button
                                        onClick={showCreateTransactionCategory}
                                        text="Добавить категорию"
                                        icon="ion-md-add"
                                        className="is-centered is-link is-outlined" />
                                        ) : null}
                            </div>

                            <Table
                                style={{paddingBottom:50}}
                                loading={transactionCategories.loading}
                                totalCount={transactionCategories.response ? transactionCategories.response.count : 0}
                                columns={{ name: 'Название', type: 'Тип', actions: '' }}
                                items={transactionCategories.response ? transactionCategories.response.results : []}
                                renderItem={(category) => (
                                    <TransactionCategoryItem
                                        category={category}
                                        onUpdate={transactionCategories.request}
                                        onDelete={transactionCategories.request}
                                        key={category.id} />
                                )} />
                        </Card>
                ) : null}
            </main>
        </Layout>
    )
}

const styles = StyleSheet.create({
    cards: {
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        gap:'1rem',
        "@media (max-width: 1280px)": {
            gridTemplateColumns:'1fr'
        },
        ":nth-child(1n) .table-parent": {
            minHeight: 0,
            marginTop:'1rem'
        }
    }
})