import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import ProtectedRoute from './components/common/ProtectedRoute'
import ConfirmEmail from './pages/ConfirmEmail'
import Group from './pages/Group'
import NotFound from './pages/NotFound'
import Login from './pages/Login'
import StudentsList from './pages/StudentsList'
import Transaction from './pages/Transaction'
import Wallet from './pages/Wallet'
import Employee from './pages/Employee'
import BaseContextWrapper from './components/common/BaseContext'
import StatisticsAcademy from './pages/StatisticsAcademy'
import StatisticsFinance from './pages/StatisticsFinance'
import Task from './pages/Task'
import Customers from './pages/Customers'
import EmployeeDetail from './pages/EmployeeDetail'
import ResetPassword from './pages/ResetPassword'
import ResetLink from './pages/ResetLink'
import EmailConfirmationMessage from './pages/EmailConfirmationMessage'
import SettingsAcademy from './pages/SettingsAcademy'
import SettingsUser from './pages/SettingsUser'
import SettingsFinance from './pages/SettingsFinance'
import InvitationSignUp from './pages/InvitationSignUp'
import ResetPasswordMessage from './pages/ResetPasswordMessage'
import { getEnv } from './utils/environment'
import Timeline from './pages/Timeline'
import Message from './pages/Message'
import GroupDetail from './pages/GroupDetail'
import AssessmentDetail from './pages/AssessmentDetail'
import Branch from './pages/Branch'
import CustomerDetail from './pages/CustomerDetail'
import EmployeeNotes from './pages/EmployeeNotes'
import GroupView from './components/GroupView'
import Debtors from './pages/Debtors'


export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
        document.querySelector("link[rel='shortcut icon']").href = `/${getEnv()}.favicon.png`
    }, [])

    return (
        <BrowserRouter>
            <BaseContextWrapper>
                <Switch>
                    <Route path="/" name="auth" component={Login} exact />
                    {/* <Route path="/sign-up" name="auth" component={SignUp} exact /> */}
                    <Route path="/reset-link" name="auth" component={ResetLink} exact />
                    <Route path="/reset-password/:key" name="auth" component={ResetPassword} exact />
                    <Route path="/confirm/:confirmationCode" component={ConfirmEmail} exact />
                    <Route path="/invitation/:code" component={InvitationSignUp} exact />
                    <Route path="/email-confirmation-message" component={EmailConfirmationMessage} exact />
                    <Route path="/reset-password-message" component={ResetPasswordMessage} exact />
                    {/* <ProtectedRoute path="/academy/leads" component={Leads} exact /> */}
                    <ProtectedRoute path="/academy/customers" component={Customers} exact />
                    <ProtectedRoute path="/academy/customers/:customerId" component={CustomerDetail} exact />
                    <ProtectedRoute path="/academy/group" component={Group} exact />
                    <ProtectedRoute path="/academy/group/:groupId" component={GroupView} exact />
                    <ProtectedRoute path="/academy/group/:groupId/students-list" component={StudentsList} exact />
                    <ProtectedRoute path="/academy/class/:classId" component={GroupDetail} exact />
                    <ProtectedRoute path="/academy/class/:classId/assessment/:assessmentId" component={AssessmentDetail} exact />
                    <ProtectedRoute path="/academy/timeline" component={Timeline} exact />
                    <ProtectedRoute path="/academy/message" component={Message} exact />
                    <ProtectedRoute path="/academy/note" component={EmployeeNotes} exact />
                    <ProtectedRoute path="/academy/debtors" component={Debtors} exact />
                    <ProtectedRoute path="/finance/wallet" component={Wallet} exact />
                    <ProtectedRoute path="/finance/wallet/:walletId/transaction" component={Transaction} exact />
                    <ProtectedRoute path="/staff/employee" component={Employee} exact />
                    <ProtectedRoute path="/staff/employee/:employeeId" component={EmployeeDetail} exact />
                    <ProtectedRoute path="/branch" component={Branch} exact />
                    <ProtectedRoute path="/statistics/academy" component={StatisticsAcademy} exact />
                    <ProtectedRoute path="/statistics/finance" component={StatisticsFinance} exact />
                    <ProtectedRoute path="/settings/academy" component={SettingsAcademy} exact />
                    <ProtectedRoute path="/settings/finance" component={SettingsFinance} exact />
                    <ProtectedRoute path="/settings/user" component={SettingsUser} exact />
                    <ProtectedRoute path="/task" component={Task} exact />
                    <Route path="" component={NotFound} exact />
                </Switch>
            </BaseContextWrapper>
        </BrowserRouter>
    )
}
