import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { FieldArray, Form, Formik } from 'formik'
import { css, StyleSheet } from "aphrodite"
import Button from '../components/common/Button'
import Layout from '../components/Layout'
import Input from '../components/common/Input'
import Checkbox from '../components/common/Checkbox'
import Loader from "../components/common/Loader";
import cn from "classnames";
import ServerErrorCustom from "../components/common/ServerErrorCustom";
import { useLoad, usePutRequest } from "../hooks/request";
import { useMessage } from "../hooks/message";
import { ASSESSMENT_DETAIL, ASSESSMENT_ITEM_LIST, ASSESSMENT_UPDATE } from "../urls";
import { getDate } from "../utils/date";
import { absents, marks, tests } from "../utils/position";
import Select from "../components/common/Select";
import { checkForAdmin } from '../utils/auth'


export default function AssessmentDetail() {
    const isAdmin = checkForAdmin()
    const { assessmentId, classId } = useParams()
    const classDetail = useLoad({ url: ASSESSMENT_DETAIL.replace('{id}', assessmentId) })
    const assessmentItems = useLoad({ url: ASSESSMENT_ITEM_LIST, params: { assessment: assessmentId, size: 40 } },)
    const assessmentsList = assessmentItems.response?.results || []
    const activeAssessments = assessmentsList.filter(item => !!item?.student?.isActive)
    const studentsList = isAdmin ? assessmentsList : activeAssessments
    const className = classDetail.response
    const [showMessage] = useMessage()

    const assessmentsUpdate = usePutRequest({ url: ASSESSMENT_UPDATE.replace('{id}', assessmentId) })

    async function onSubmit(values, actions) {
        const { success } = await assessmentsUpdate.request({ data: values });
        // await onCreate(values)
        if (success) {
            await classDetail.request()
            // Fetch the updated assessment items
            await assessmentItems.request();
            showMessage('Данные успешно изменены.', 'is-success');
            actions.resetForm();
        }
    }

    async function onCreate(data) {
        await assessmentsUpdate.request({ data });
    }

    const studentsInitialValues = studentsList.map(i => ({
            name: i.student.customer.name,
            student: i.student.id,
            mark: i.mark ?? '',
            testScore: i.testScore,
            homeworkStatus: i.homeworkStatus ?? '',
            assessment: i.assessment,
            isAbsent: i.isAbsent,
            comment: i.comment ?? '',
            isAbsentStatus: i.isAbsentStatus ?? '',
        }))
    const options = Object.entries(tests).map(([id, name]) => ({ id, name }))
    const mark = Object.entries(marks).map(([id, name]) => ({ id, name }))
    const absent = Object.entries(absents).map(([id, name]) => ({ id, name }))
    return (
        <Layout>
            <ServerErrorCustom error={assessmentsUpdate.error}/>
            <div className={css(styles.assessmentForm)}>
                <div className={css(styles.assessmentFormDiv)}>
                    {!assessmentItems.loading ? <> {assessmentItems?.response?.results && (
                        <Formik
                            initialValues={{
                                students: studentsInitialValues,
                                testStatus: classDetail.response ? classDetail.response.testStatus : false,
                                testCount: classDetail.response ? classDetail.response.testCount : 0,
                            }}
                            onSubmit={onSubmit}>
                            {({ values }) => (
                                <Form>

                                    <NavLink style={{marginRight:'1rem'}} className="button is-white" to={`/academy/class/${classId}`}>
                                        <i className="icon has-text-link ion-md-arrow-back is-size-4"/>
                                    </NavLink>


                                    <span
                                        style={{marginBottom:'1rem'}}
                                        className="tag is-link mr-5 is-large is-light has-text-black">
                                        <div className="mt-2">
                                            <Checkbox
                                                className="mt-3"
                                                label="Введите номер теста"
                                                name="testStatus"
                                                type="checkbox"
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                        </div>
                                        <Input
                                            type="number"
                                            disabled={!values.testStatus}
                                            name="testCount"
                                            className="is-small"/>
                                    </span>
                                    <span
                                        style={{marginLeft : 10}}
                                        className="tag is-link ml-2 is-large is-light has-text-black mb-1">Дата: {className ? getDate(className.createdAt) : ''}
                                    </span>

                                    <div style={{overflow:'auto'}}>
                                        <table className='table is-striped is-fullwidth'>
                                            <tbody>
                                            <tr>
                                                <th>№</th>
                                                <th>Имя студента</th>
                                                <th>Перекличка</th>
                                                <th>Перекличка статус</th>
                                                <th>Домашнее задание</th>
                                                <th>Оценка</th>
                                                {values.testStatus ? <th>Результат Тест</th> : null}
                                                <th>Комментарии</th>

                                            </tr>
                                            <FieldArray name="students">
                                                {() => (
                                                    values.students.map((student, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <span>{index + 1}</span>
                                                            </td>
                                                            <td>
                        <span
                            className={cn(
                                values.students[index].homeworkStatus && values.students[index].mark ? 'has-text-black' : 'has-text-black'
                            )}
                        >
                            {student.name}
                        </span>
                                                            </td>
                                                            <td>


                                                                <Checkbox
                                                                    name={`students[${index}].isAbsent`}
                                                                    type="checkbox"
                                                                    style={{ transform: 'scale(1.5)' }}
                                                                />
                                                            </td>

                                                            <td>
                                                                <Select
                                                                    disabled={student.isAbsent}
                                                                    options={absent}
                                                                    className="is-small"
                                                                    name={`students[${index}].isAbsentStatus`}
                                                                    empty
                                                                />
                                                            </td>
                                                            <td className="is-5">
                                                                <Select
                                                                    disabled={!student.isAbsent}
                                                                    options={options}
                                                                    className="is-small"
                                                                    name={`students[${index}].homeworkStatus`}
                                                                    empty
                                                                />
                                                            </td>
                                                            <td>
                                                                <Select
                                                                    disabled={!student.isAbsent}
                                                                    options={mark}
                                                                    className="is-small"
                                                                    name={`students[${index}].mark`}
                                                                    empty
                                                                />
                                                            </td>
                                                            {values.testStatus ? <td>
                                                                <Input
                                                                    disabled={!student.isAbsent}
                                                                    className="is-small"
                                                                    name={`students[${index}].testScore`}
                                                                    placeholder="Введите результат теста"
                                                                    type="text"
                                                                />
                                                            </td> : null}
                                                            <td>
                                                                <Input
                                                                    disabled={!student.isAbsent}
                                                                    className="is-small"
                                                                    name={`students[${index}].comment`}
                                                                    placeholder="Введите свой комментарий ..."
                                                                    type="text"
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </FieldArray>
                                            </tbody>
                                        </table>
                                    </div>
                                    {assessmentItems.response && assessmentItems.response.results.length > 0 ? (
                                        <Button
                                            loading={assessmentsUpdate.loading}
                                            type="submit"
                                            icon="ion-md-create"
                                            text="Обновлять"
                                            style={{marginTop:'1rem'}}
                                            className="is-link is-outlined is-pulled-right mb-3 light-info"/>
                                    ) : null}
                                </Form>
                            )}
                        </Formik>
                    )}</> : <Loader center large/>}
                </div>
            </div>

        </Layout>
    )
}


const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    assessmentForm: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    assessmentFormDiv: {
        '@media (max-width: 500px)': {
            width: '800px'
        },
    },
})
