import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import { useLoad } from '../hooks/request'
import { USERS_LIST } from '../urls'
import Input from './common/Input'
import Button from './common/Button'
import Select from './common/Select'

export default function TaskForm({ onSubmit, onCancel, loading, initialValues }) {
    const users = useLoad({ url: USERS_LIST })

    return (
        <Formik initialValues={{
            title: '',
            ...initialValues,
            assigned: initialValues ? initialValues.assigned.id : '',
            dueTime: initialValues && initialValues.dueTime ? initialValues.dueTime : '',
            description: initialValues ? initialValues.description : '',
        }} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="title"
                    label="Заголовок"
                    placeholder="Введите заголовок задачи"
                    disabled={loading}
                    validate={required} />

                <Select
                    empty
                    name="assigned"
                    label="Ответственный"
                    validate={required}
                    optionLabel={(item) => `${item.firstName}(${item.email})`}
                    options={users.response ? users.response.results : []} />

                <Input
                    name="dueTime"
                    label="Дату окончание"
                    type="datetime-local"
                    optional
                    disabled={loading} />

                <Input name="description" optional component="textarea" label="Описание" />
                <br />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    disabled={loading}
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    disabled={loading}
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
