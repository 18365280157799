import React from 'react'
import { NavLink } from 'react-router-dom'
import { tags } from './CustomerType'
import CustomerActions from './CustomerActions'
import { useModal } from '../hooks/modal'
import CustomerUpdate from './CustomerUpdate'
import CustomerInfo from './CustomerInfo'
import CustomerGroups from './CustomerGroups'
import Dropdown, { DropdownItem } from './common/Dropdown'
import { CUSTOMER_DETAIL, CUSTOMER_MOVE } from '../urls'
import { usePutRequest } from '../hooks/request'
import Loader from './common/Loader'
import { getDate, getDateNotRU, getDateTime } from "../utils/date";

export default function CustomerTableItem({ customer, status,customers, statusList, showLabels, onUpdate, onDelete, type, index }) {
    const url = CUSTOMER_DETAIL.replace('{id}', customer.id)
    const customerUpdate = usePutRequest({ url })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <CustomerUpdate
            customer={customer}
            onCancel={() => hideUpdateModal()}
            onSuccess={(values) => {
                onUpdate(customer.id, values)
                hideUpdateModal()
            }} />,
    )

    const [showInfoModal, hideInfoModal] = useModal(
        <CustomerInfo
            onCancel={() => hideInfoModal()}
            onChange={() => {
                hideInfoModal()
                showUpdateModal()
            }}
            customer={customer}
            onUpdate={(newCustomerId, newCustomer) => {
                // TODO
                onUpdate(newCustomerId, newCustomer)
                hideInfoModal()
            }}
            onDelete={(newCustomerId, newCustomer) => {
                onUpdate(newCustomerId, newCustomer)
                hideInfoModal()
            }} />,
    )

    async function changeStatus(state) {
        await customerUpdate.request({
            url: CUSTOMER_MOVE.replace('{id}', customer.id),
            data: { state, position: 0 },
        })
        onUpdate(customer.id, { state })
    }

    return (
        <tr key={customer.id}>
            <td>{index + 1}</td>


            <td className="pointer">
                <NavLink to={`/academy/customers/${customer.id}`}>
                    <span>{customer.name}</span>
                    &nbsp;
                    {showLabels ? tags[customer.type] : null}
                </NavLink>
            </td>


            <td>
                {customerUpdate.loading && status ? <Loader/> : (
                    <Dropdown trigger={(
                        <div style={{ background: status && status.color ? status.color : 'is-success' }}
                             className="tag has-text-white pointer">
                            {status && status.title}
                            <i className="icon ion-md-arrow-dropdown"/>
                        </div>
                    )}>
                        {statusList.map((item) => (
                            <DropdownItem onClick={() => changeStatus(item.id)} key={item.id} text={item.title}/>
                        ))}
                    </Dropdown>
                )}
            </td>

            <td><CustomerGroups customer={customer}/></td>
            <td>{customer?.subject.map((item) => (
                <span key={item?.id} style={{ marginRight: 5 }}
                      className="tag is-light is-danger is-small mr-3">{item.name}</span>))}</td>
            <td>{customer?.teacher.map((item) => (
                <span key={item?.id} style={{ marginRight: 5 }}
                      className="tag is-light  is-small mr-3">{item.name}</span>))}</td>
            <td style={{whiteSpace:'nowrap'}}>{getDateNotRU(customer.createdAt)}</td>
            <td>
                <CustomerActions
                    customers={customers}
                    customer={customer}
                    onDelete={onDelete}
                    onTypeChanged={onUpdate}
                    onClickUpdate={showUpdateModal}
                    paramsType={type}/>
            </td>
        </tr>
    )
}
