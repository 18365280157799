import React, { Fragment, useEffect } from 'react'
import cn from 'classnames'
import { Droppable } from 'react-beautiful-dnd'
import { css, StyleSheet } from 'aphrodite'
import { useModal } from '../hooks/modal'
import CustomerBoardCard from './CustomerBoardCard'
import CustomerCreate from './CustomerCreate'
import { useInfiniteScroll2 } from '../hooks/request'
import { CUSTOMER_LIST } from '../urls'
import { useQueryParams } from '../hooks/queryString'
import { isEmpty } from 'lodash'


export default function CustomerBoardColumn({
    columnKey,
    column,
    lineColor,
    showLabels,
    type,
    searchParams,
    onCustomerInstance, // New prop to pass the customer instance up
}) {
    const params = useQueryParams()
    const customers = useInfiniteScroll2({url:CUSTOMER_LIST, params:{...params, state:column.id, ...searchParams }}, [searchParams])
    const customersList = customers.response?.results || []

     // Pass the customer instance to the parent via onCustomerInstance
    useEffect(() => {
        onCustomerInstance(columnKey, customers);
    }, [customers, columnKey, onCustomerInstance]);

    async function onUpdate(customerId, data) {
        const results = isEmpty(data) ? (
            await customersList.filter((item) => item.id !== customerId)
        ) : (
            await customersList.map((item) => (
                item.id === customerId ? { ...item, ...data } : item
            ))
        )
        customers.setResponse({ count: customers.response.count, results })
    }

    const [showCreateModal, hideCreateModal] = useModal(
        <CustomerCreate
            state={columnKey}
            onSuccess={(data) => {
                customers.reload()
                hideCreateModal()
            }}
            onCancel={() => hideCreateModal()} />,
    )

    return (
        <Fragment>
            <div className={css(styles.header)}>
                <span className={css(styles.headerTitle)}>
                    {column.title} &nbsp;
                    <span className={css(styles.count)}>({column.customersCount || 0})</span>
                </span>

                <hr style={{ background: lineColor }} className={css(styles.line)} />
            </div>

            <div onClick={showCreateModal} className={cn('box has-text-success', css(styles.createButton))}>
                <i className="icon ion-md-add" />
                Добавить
            </div>

            <Droppable droppableId={String(columnKey)} >
                {(provider, snapshot) => (
                    <div ref={provider.innerRef} className={css(snapshot.isDraggingOver && styles.active)} style={{height:'100%'}}>
                        {customersList.map((customer, index) => (
                            <CustomerBoardCard
                                customer={customer}
                                showLabels={showLabels}
                                index={index}
                                onUpdate={onUpdate}
                                key={customer.id}
                                type={type}
                                reload={customers.reload}
                                />
                        ))}
                        <div ref={customers.ref} style={{ height: 1 }} />
                        {provider.placeholder}
                    </div>
                )}
            </Droppable>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    createButton: {
        whiteSpace: 'nowrap',
        marginBottom: '0.5rem',
        borderRadius: '3px',
        cursor: 'pointer',
        padding: '0.7rem',
        ':hover': {
            background: '#fbf9f9',
        },
    },
    active: {
        background: '#e8e8e8',
        borderRadius: '3px',
    },
    line: {
        margin: '0.5rem 0 1rem',
    },
    count: {
        color: '#989797',
    },
    header: {
        background: 'whitesmoke',
        margin: '0 -0.6rem',
        padding: '0 0.6rem',
        position: 'sticky',
        top: '0',
        whiteSpace: 'nowrap',
        zIndex: 1,
    },
    headerTitle: {
        marginLeft: '0.4rem',
    },
})
