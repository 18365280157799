import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { checkPermission as cp } from '../utils/auth'

export default function SettingsTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                {cp('academy.view_customer') ? (
                    <li className={cn({ 'is-active': path.startsWith('/settings/academy') })}>
                        <NavLink to="/settings/academy">Академия</NavLink>
                    </li>
                ) : null}

                {cp('finance.view_wallet') ? (
                    <li className={cn({ 'is-active': path.startsWith('/settings/finance') })}>
                        <NavLink to="/settings/finance">Финансы</NavLink>
                    </li>
                ) : null}

                <li className={cn({ 'is-active': path.startsWith('/settings/user') })}>
                    <NavLink to="/settings/user">Аккаунт пользователя</NavLink>
                </li>
            </ul>
        </div>
    )
}
