import React from 'react'
import { Form, Formik } from 'formik'
import find from 'lodash/find'
import map from 'lodash/map'
import Select from './common/Select'
import { required } from '../utils/validators'
import Input from './common/Input'
import Button from './common/Button'
import { useLoad } from '../hooks/request'
import { GROUPS_LIST } from '../urls'
import Checkbox from './common/Checkbox'

export default function CustomerInfoGroupsForm({
    initialValues = {},
    onSubmit,
    onCancel,
    customer,
    loading,
}) {
    const groups = useLoad({ url: GROUPS_LIST })
    const groupsIds = map(customer.students, 'group.id')
    const groupOptions = groups.response ? groups.response.results.filter(
        (group) => (!groupsIds.includes(group.id) || (initialValues.group && initialValues.group.id === group.id))
            && group.isArchive === false,
    ) : []

    function onGroupChange(groupId, values, setFieldValue) {
        const group = find(groups.response.results, { id: parseInt(groupId, 10) })
        setFieldValue('group', groupId)
        if (values.price === '') {
            setFieldValue('price', group ? group.price : '')
        }
        // if (values.lessonCount === '') {
        //     setFieldValue('lessonCount', group.lessonCount ?? '')
        // }
    }

    const handleOnSubmit = (d, actions) => {
        const data = { ...d, startedDate: d.startedDate || undefined, endedDate: d.endedDate || undefined }
        onSubmit(data, actions)
    }

    return (
        <Formik initialValues={{
            startedDate: '',
            endedDate: '',
            // lessonCount: '',
            isStudent: '',
            price: '',
            ...initialValues,
            group: initialValues.group ? initialValues.group.id : '',
        }} enableReinitialize onSubmit={handleOnSubmit}>
            {({ setFieldValue, values }) => (
                <Form>
                    <Select
                        label="Группа"
                        empty
                        placeholder="Группа"
                        name="group"
                        options={groupOptions}
                        validate={required}
                        onChange={({ target }) => onGroupChange(target.value, values, setFieldValue)}
                        loading={groups.loading} />
                    <Input
                        label="Цена"
                        name="price"
                        placeholder="Стоимость месячного абонемента"
                        disabled={loading}
                        validate={required}
                        type="number" />
                    <div className="columns">
                        {/* <div className="column">
                            <Input
                                label="Количество уроков"
                                name="lessonCount"
                                placeholder="Введите количество уроков"
                                disabled={loading}
                                type="number" />
                        </div> */}
                        <div style={{ marginTop: 30 }} className="column pt-6 is-flex">
                            <Checkbox style={{ fontSize: 100 }} className="" label="Положение дел"
                                name="isStudent" />
                            {values.isStudent ? <span className="tag is-light is-danger">Остановлено</span>
                                : <span className="tag is-light is-success">Продолжать</span>}
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <Input
                                label="Дата начала"
                                name="startedDate"
                                disabled={loading}
                                type="date" />
                        </div>
                        <div className="column">
                            <Input
                                name="endedDate"
                                label="Дата окончания"
                                disabled={loading}
                                type="date" />
                        </div>
                    </div>
                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        disabled={loading}
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        disabled={loading}
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
