import React from 'react'
import { usePostRequest } from '../hooks/request'
import { TRANSACTION_LIST } from '../urls'
import TransactionForm from './TransactionForm'

export default function TransactionCreate({ onCancel, onSuccess, wallet, type }) {
    const transactionCreate = usePostRequest({ url: TRANSACTION_LIST })

    async function onSubmit(data) {
        await transactionCreate.request({ data: { ...data, wallet } })
        onSuccess()
    }

    return (
        <div>
            <b>Добавить {type === 'outcome' ? 'расход' : 'приход'}</b><br /><br />
            <TransactionForm onSubmit={onSubmit} type={type} onCancel={onCancel} loading={transactionCreate.loading} />
        </div>
    )
}
