import React from 'react'
import { useLoad } from '../hooks/request'
import { STATISTICS_FINANCE } from '../urls'
import StatisticsFinanceAccountantLine from './StatisticsFinanceAccountantLine'
import Card from './common/Card'


export default function StatisticsFinanceAccountant() {
    const finance = useLoad({ url: STATISTICS_FINANCE })

    return (
        <Card>
            <div className="title is-5 has-text-centered">
                Статистика Тракзакции
            </div>

            <StatisticsFinanceAccountantLine
                data={finance.response}
                loading={finance.loading} />
        </Card>
    )
}
