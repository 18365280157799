/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { checkPermission as cp } from '../utils/auth'
import { useModal } from '../hooks/modal'
import { useDeleteRequest } from '../hooks/request'
import { SUBJECT_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import Loader from './common/Loader'
import SubjectUpdate from './SubjectUpdate'


export default function SubjectItem({ item, onUpdate, onDelete }) {
    const deleteRoom = useDeleteRequest()
    const [showMessage] = useMessage()

    async function deleteColumn() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await deleteRoom.request({ url: SUBJECT_DETAIL.replace('{id}', item.id) })

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <SubjectUpdate room={item} onSuccess={async () => {
            await onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <tr>
            <td>
                {item.name}
            </td>

            <td>
                <div className="is-pulled-right is-flex">
                    {cp('academy.delete_room') ? (
                        !deleteRoom.loading ? (
                            <i onClick={deleteColumn} className="icon pointer ion-md-trash" />
                        ) : <Loader className="icon" />
                    ) : null}

                    {cp('academy.change_room') ? (
                        <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                    ) : null}

                </div>
            </td>
        </tr>
    )
}
